import React from 'react';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Link from '@nubank/nuds-web/components/Link/Link';

import websitePage from 'hoc/websitePage/websitePage';
import withApplicationFormDrawer from 'hoc/withApplicationFormDrawer/withApplicationFormDrawer';
import Section from 'components/structure/Section';
import Block from 'components/structure/Block/Block';

function Error() {
  return (
    <Section>
      <Block blockWidth="34rem" textAlign="left">
        <Typography marginBottom="8x" variant="heading2" tag="h1">
          Ops!
        </Typography>

        <Typography marginBottom="8x" variant="heading4" tag="h2">
          Não conseguimos encontrar a página que você está procurando.
        </Typography>

        <Typography marginBottom="8x" variant="heading4" tag="h2">
          Não se preocupe pois ela está em boa companhia com outras coisas que
          não existem no mundo do Nubank:
        </Typography>

        <Box
          as="ul"
          paddingBottom="2x"
        >
          <li>
            <Typography>Porta-giratória</Typography>
          </li>
          <li>
            <Typography>Boletos chegando pelo correio</Typography>
          </li>
          <li>
            <Typography>Pontos que já venceram</Typography>
          </li>
          <li>
            <Typography>Senhas pra falar com o gerente</Typography>
          </li>
          <li>
            <Typography>Tarifas escondidas</Typography>
          </li>
        </Box>

        <Link href="/" color="primary" variant="action">
          Voltar para a página principal
        </Link>
      </Block>
    </Section>
  );
}

export default withApplicationFormDrawer(
  websitePage({
    routeKey: '404',
  })(Error),
);
